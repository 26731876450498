function Spinner({ className }) {
    return (
      <div className={`lds-spinner ${className ? className : ""}`}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    );
  }
  
  export default Spinner;
  