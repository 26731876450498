import React, { useState, useEffect } from "react";
import { Container, Card, CardTitle, CardBody, Row, Button, Table, Input } from "reactstrap";
import Layout from "../../Layout";
import Urunler from "./Urunler";


const UrunlerMain = () => {


  return (
    <Layout showFooter={true}>
      <main className="home">
        <Container>
          <div className="h1">Ürünler</div>
        </Container>

       <Urunler/>
       {/* <Footer/> */}
      </main>
    </Layout>
  );
};

export default UrunlerMain;
