import { useEffect, useRef } from "react";
import { Popover as RPoppover, PopoverBody } from "reactstrap";

function Popover(props) {
  const {
    children,
    className,
    target,
    toggle,
    isOpen,
    placement,
    setPopoverOpen,
    popoverOpen,
  } = props;
  const popoverRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setPopoverOpen(false);
    }
  };

  useEffect(() => {
    if (popoverOpen) window.addEventListener("click", handleClickOutside);
    else window.removeEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [popoverOpen]);

  return (
    <RPoppover
      className={className}
      target={target}
      toggle={toggle}
      isOpen={isOpen}
      placement={placement}
      innerRef={popoverRef}
    >
      <PopoverBody className="px-0">{children}</PopoverBody>
    </RPoppover>
  );
}

export default Popover;
