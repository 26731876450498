import { string, object } from "yup";

const createEmailValidateSchema = (fieldName, required) => {
  return object().shape({
    [fieldName]: string()
      .email(required ? "Lütfen geçerli bir e-mail adresi giriniz." : true)
      .required(required ? "Bu alan zorunludur." : true),
  });
};

const createValidationPasswordSchema = (
  fieldName,
  required,
  minLength,
  maxLength
) => {
  return object().shape({
    [fieldName]: string()
      .required(required ? "Bu alan zorunludur." : "")
      .min(
        minLength || 0,
        minLength ? `Minimum ${minLength} karakter içermelidir.` : ""
      )
      .max(
        maxLength || Infinity,
        maxLength ? `Maximum ${maxLength} karakter uzunluğunda olmalıdır.` : ""
      )
      .matches(/[A-Z]/, "En az bir büyük harf içermelidir."),
  });
};

const createGeneralValidateSchema = (
  fieldName,
  required,
  minLength,
  maxLength
) => {
  return object().shape({
    [fieldName]: string()
      .required(required ? "Bu alan zorunludur." : "")
      .min(
        minLength || 0,
        minLength ? `Minimum ${minLength} karakter içermelidir.` : ""
      )
      .max(
        maxLength || Infinity,
        maxLength ? `Maximum ${maxLength} karakter uzunluğunda olmalıdır.` : ""
      ),
  });
};

// CONTACT
const createValidationContactTelSchema = (fieldName, required) => {
  return object().shape({
    [fieldName]: string()
      .required(required ? "Bu alan zorunludur." : "")
      .matches(
        /^\+\d{2}\s\d{3}\s\d{3}\s\d{4}$/,
        "Lütfen geçerli bir telefon numarası girin. (Örnek: +90 555 444 3333)"
      ),
  });
};

export {
  createValidationPasswordSchema,
  createGeneralValidateSchema,
  createValidationContactTelSchema,
  createEmailValidateSchema,
};
