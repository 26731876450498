import React, { useEffect } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const PAGE_SIZE = 10;

function Paginator(props) {
  const PAGE_NUMBER = Math.floor(props.len / PAGE_SIZE) + 1;

  const changeActiveLink = (currentPage) => {
    for (
      let i = 0;
      i < document.getElementsByClassName("page-item").length;
      i++
    ) {
      document
        .getElementsByClassName("page-item")
        [i].classList.remove("active");
    }

    document
      .getElementsByClassName("page-item")
      [currentPage].classList.add("active");
  };

  const changePage = (currentPage) => {
    const indexOfLastTodo = currentPage * PAGE_SIZE;
    const indexOfFirstTodo = indexOfLastTodo - PAGE_SIZE;
    const pagedProducts = props.data.slice(indexOfFirstTodo, indexOfLastTodo);
    props.setTempProducts(props.loadPage(pagedProducts));

    changeActiveLink(currentPage);
  };

  const links = () => {
    const l = [];
    for (let i = 0; i < PAGE_NUMBER; i++) {
      l.push(
        <PaginationItem key={i + 1}>
          <PaginationLink onClick={(e) => changePage(e.target.innerHTML)}>
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return l;
  };

  useEffect(() => {
    changePage(1);
  }, []);

  const goPrev = (currentPage) => {
    if (currentPage == 1) return;
    changePage(currentPage - 1);
  };

  const goNext = (currentPage) => {
    if (currentPage == PAGE_NUMBER) return;
    changePage(Number(currentPage) + 1);
  };

  return (
    <Pagination aria-label="Page navigation example ">
      <PaginationItem >
        <PaginationLink 
          previous
          onClick={() =>
            goPrev(
              document.getElementsByClassName("page-item active")[0].innerText
            )
          }
        />
      </PaginationItem>
      {links()}
      <PaginationItem>
        <PaginationLink
          next
          onClick={() =>
            goNext(
              document.getElementsByClassName("page-item active")[0].innerText
            )
          }
        />
      </PaginationItem>
    </Pagination>
  );
}

export default Paginator;
