import { lazy, Suspense } from "react";
import { Routes as Switcher, Route, Navigate } from "react-router-dom";
import Loader from "../components/Loader";
import UseAuth from "../hooks/UseAuth";
import HomeMain from "../Pages/HomeMain/HomeMain";
import Login from "../Pages/Login";
import BlogMain from "../Pages/Blog/BlogMain";
import UrunlerMain from "../Pages/Urunler/UrunlerMain";
import ProductAddFromPage from "../Pages/Urunler/ProductAddFormPage";
import BlogAddFormPage from "../Pages/Blog/BlogAddFormPage";
const NotFound = lazy(() => import("../components/NotFound/NotFound"));
function Routes() {
    const routeConfig = [
      {
        path: "/login",
        component: Login,
      },
      {
        path: "/home",
        component: HomeMain,
      },
      {
        path: "/blog",
        component: BlogMain,
      },

      {
        path: "/urunler",
        component: UrunlerMain,
      },
      {
        path: "/form",
        component: ProductAddFromPage,
      },
      {
        path: "/form2",
        component: BlogAddFormPage,
      },
      {
        path: "not-found",
        component: NotFound,
      }
    
    
    ];
    return (
    <Switcher>
        {routeConfig.map((route) => (
          <Route
            key={route.path}
            exact
            path={route.path}
            element={
              <Suspense fallback={<Loader />}>
                <route.component />
              </Suspense>
            }
          />
        ))}
      
      </Switcher>
  
    );
  }
  
  export default Routes;