import React, { Fragment } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Aside from "./Aside";

function Layout({ children, showFooter = false }) {
  return (
    <Fragment>
      <Header />
      <Aside />
      {children}
      {showFooter && <Footer />}
    </Fragment>
  );
}

export default Layout;
