import React, { useState } from "react";
import { RMIUploader } from "react-multiple-image-uploader";
import CustomAlert from "../components/CustomAlert/CustomAlert";

function Slider(props) {
  const [visible, setVisible] = useState(false);
  const [photoUploaded, setPhotoUploaded] = useState(false);

  const handleSetVisible = () => {
    setVisible(!visible);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const onUpload = (data) => {
    console.log("Upload files", data);
    props.imageUpload(data[0].file);
    setPhotoUploaded(true); 
  };

  const onSelect = (data) => {
    console.log("Select files", data);
    setPhotoUploaded(true);
  };

  const onRemove = (id) => {
    console.log("Remove image id", id);
    setPhotoUploaded(false);
  };

  return (
    <div>
      {!photoUploaded && ( 
        <CustomAlert
          message="Fotoğrafı yükledikten sonra bulut iconuna tıklayın!"
          type="warning"
        />
      )}
      <RMIUploader
        isOpen={visible}
        hideModal={hideModal}
        onSelect={onSelect}
        onUpload={onUpload}
        onRemove={onRemove}
        dataSources={props.dataSources}
      />
    </div>
  );
}

export default Slider;
