import React from "react";
// import { Route, Routes } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import UseAuth from "./hooks/UseAuth";
import "./App.css";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import Routes from "./routes";


function App() {
  return (
    <div>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <UseAuth>
            {" "}
            <Routes />
          </UseAuth>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
