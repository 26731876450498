import React, { useState, useEffect } from "react";
import { Container, Card, CardTitle, CardBody, Row, Button, Table, Input } from "reactstrap";
import Layout from "../../Layout";

import Blog from "./Blog";


const BlogMain = () => {


  return (
    <Layout showFooter={true}>
      <main className="home">
        <Container>
          <div className="h1">Bloglar</div>
        </Container>

       <Blog/>
       {/* <Footer/> */}
      </main>
    </Layout>
  );
};

export default BlogMain;
