import React, { useState, useCallback } from "react";
import { FormFeedback, FormGroup, FormText, Input, Label } from "reactstrap";
import InputMask from "react-input-mask";

export default function InputFieldWithError({
  id,
  validationSchema,
  formErrors,
  setFormErrors,
  args,
  label,
  mask,
  formText = "",
  value,
  setValue,
}) {
  const validateField = useCallback(
    async (fieldName, value) => {
      try {
        await validationSchema.validateAt(fieldName, { [fieldName]: value });
        setFormErrors("");
      } catch (error) {
        setFormErrors(error.message);
      }
    },
    [validationSchema, setFormErrors]
  );

  const handleChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      setValue(value);
      validateField(name, value);
    },
    [validateField]
  );

  const isInvalid = Boolean(formErrors);
  const isValid = !isInvalid && value.length > 0;

  return (
    <FormGroup>
      {label && <Label htmlFor={id}>{label}</Label>}
      {mask ? (
        <InputMask mask={mask} value={value} onChange={handleChange}>
          {(inputProps) => (
            <Input
              id={id}
              name={id}
              {...inputProps}
              className={`form-control ${isInvalid ? "is-invalid" : ""} ${
                isValid ? "is-valid" : ""
              }`}
              {...args}
            />
          )}
        </InputMask>
      ) : (
        <Input
          id={id}
          name={id}
          value={value}
          onChange={handleChange}
          className={`form-control ${isInvalid ? "is-invalid" : ""} ${
            isValid ? "is-valid" : ""
          }`}
          {...args}
        />
      )}
      <FormText>{formText}</FormText>
      {formErrors && <FormFeedback invalid>{formErrors}</FormFeedback>}
    </FormGroup>
  );
}
