import { useState, useEffect, useRef } from "react";
import NavBarItem from "../../components/NavbarItem";
import { Button } from "reactstrap";
import ThemeSwitcher from "../../components/ThemeSwitcher";
import { useSelector } from "react-redux";

function Aside() {
  const theme = useSelector((state) => state?.theme?.theme);
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLogOut, setIsLogOut] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const onLogOut = (e) => {
    setIsLogOut(true);
    setTimeout(() => {
      window.location.href = "/login";
      localStorage.removeItem("asdf");
    }, 2000);
  };
  const asideRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (asideRef.current && !asideRef.current.contains(event.target)) {
        setIsActive(false);
      }
    }

    if (isActive) {
      document.body.style.overflow = "hidden";
      window.addEventListener("click", handleClickOutside);
    } else {
      document.body.style.overflow = "auto";
      window.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.body.style.overflow = "auto";
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isActive]);

  return (
    <aside className={isActive ? "active" : ""} ref={asideRef}>
      <div className="d-flex flex-column justify-content-between h-100">
        <div>
          <div
            className={`d-flex align-items-center justify-content-start gap-2 nav-item menu ${
              isActive ? "active" : ""
            }`}
          >
            <Button
              color="link"
              className="nav-item-img-wrapper px-0"
              tabIndex={1}
              onClick={() => setIsActive(!isActive)}
            >
              <div className="menu-line"></div>
              <div className="menu-line"></div>
              <div className="menu-line"></div>
            </Button>
          </div>

          <ul
            className={`${
              isActive ? "d-flex" : "d-none d-lg-flex"
            } flex-column gap-2 align-items-start mt-4`}
          >

<li>
              <NavBarItem
                title="Anasayfa"
                to="home"
                icon={`home-${theme === "dark" ? "white" : "black"}.svg`}
                tabIndex={2}
              />
            </li>
            <li>
              <NavBarItem
                title="Ürünler"
                to="urunler"
                icon={`validator-${theme === "dark" ? "white" : "black"}.svg`}
                tabIndex={2}
              />
            </li>
            <li>
              <NavBarItem
                title="Blog"
                to="blog"
                icon={`table-${theme === "dark" ? "white" : "black"}.svg`}
                tabIndex={3}
              />
            </li>

          </ul>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div
            className={`${
              isActive ? "d-flex" : "d-none d-lg-flex"
            } align-items-center justify-content-start gap-2 nav-item`}
          >
            <div className="nav-item-img-wrapper text-center" onClick={(e) => onLogOut(e)}>
              <img
                src={`/assets/icons/log-out-${
                  theme === "dark" ? "white" : "black"
                }.svg`}
                alt="nav-icon"
                className="nav-item-img"
              />
            </div>
            <div className="nav-item-title "onClick={(e) => onLogOut(e)} >Çıkış Yap</div>
          </div>
          <ThemeSwitcher className={!isActive && "d-none"} />
        </div>
      </div>
    </aside>
  );
}

export default Aside;
