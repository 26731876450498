import React, { useState, useEffect } from "react";
import { Container, Card, CardTitle, CardBody, Row, Button, Table, Input } from "reactstrap";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FormApi } from "../../api/form_api";
import ReactHtmlParser from "react-html-parser";
import Layout from "../../Layout";
import Paginator from "../../components/Paginator";
import Zoomer from "../../components/Zoom";
const endpoint = "productdenz";

const Urunler = () => {
  const formApi = new FormApi(endpoint);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [productsAll, setProductsAll] = useState([]);
  const [tempProducts, setTempProducts] = useState([]);
  const [productDetail, setProductDetail] = useState({});
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [search, setSearch] = useState("");

  const goEditProduct = (product) => {
    navigate("/form", { state: { product } });
  };

  const deleteProduct = async (e, product) => {
    e.preventDefault();
    console.log("silllll",product)
    alertify.confirm(
      "Silme İşlemi",
      `${product.code} nolu ürünü silmek istediğinizden emin misiniz?`,
      async function () {
        try {
          console.log(" delete product:", product);
          const obj = { _id: product._id, img: product.img };
          const result = await formApi.deleteImage(obj);
          if (result) alertify.success("Ürün silindi..", 2.5);
        } catch (e) {
          alertify.error("Ürün silinemedi..", 2.5);
        }
      },
      function () {
        alertify.error("İşlemden vazgeçildi...", 20);
      }
    );
  };

  const showDetail = (product) => {
    setProductDetail(product);
    toggle();
  };

  const loadPage = (products) => {
    // Filter out products with undefined or null code properties
    const validProducts = products.filter(product => product.code !== undefined && product.code !== null);
  
    // Sort the valid products array
    const sortedProducts = validProducts.sort((a, b) => {
      const numA = parseInt(a.code, 10);
      const numB = parseInt(b.code, 10);
  
      // Use numeric comparison if both are valid numbers
      if (!isNaN(numA) && !isNaN(numB)) {
        return numA - numB;
      }
  
      // Fallback to string comparison
      return (a.code || '').localeCompare(b.code || '');
    });
  
    // Map and return the sorted products
    return sortedProducts.map((product) => (
      <tr
        className="align-middle text-center"
        key={product.code}
        onClick={(e) => showDetail(product)}
      >
        <td>
          <img
            src={`https://restapi.denzwater.com/uploads/${product.img}`}
            height={50}
          />
        </td>
  
        <td>{product.code}</td>
        <td>{product.name}</td>
        <td>{product.categories}</td>
        <td className="option">
          <BiEdit onClick={(e) => goEditProduct(product)} />
          <AiFillDelete onClick={(e) => deleteProduct(e, product)} />
        </td>
      </tr>
    ));
  };
  
  
  

  const onSearchHandler = (e) => {
    e.preventDefault();
    const filteredProducts = [];
    products.forEach((product) => {
      Object.entries(product).forEach(([key, value]) => {
        if (key === "name" && value.toLowerCase().includes(search))
          filteredProducts.push(product);
      });
    });
    setTempProducts(loadPage(filteredProducts));
  };
  useEffect(() => {
    (async () => {
      const res = await formApi.getPage();
      setProducts(res);
      setTempProducts(loadPage(res));
      setProductsAll(res);
    })();
  }, []);

  return (
    <Layout>
      <main className="home">


        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                  <CardTitle>
                    {/* <b>Bloglar</b> */}
                  </CardTitle>
                  <br />
                  <div className="main p-3">
                  <Zoomer toggle={toggle} modal={modal} product={productDetail} />

                  <div className="d-flex justify-content-between p-3">
        <Button
          style={{ background: "black" }}
          size="sm"
          onClick={() => navigate("/form")}
        >
          Ürün Ekle
        </Button>

        <form className="d-flex">
          <Input
            type="search"
            name="search"
            id="exampleSearch"
            placeholder="Ürün adına göre arayın..."
            value={search}
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            onChange={(e) => {
              if (e.target.value == "") {
                setProducts(productsAll);
                setTempProducts(loadPage(productsAll));
              } else {
                let filteredProducts = [];
                products.forEach((product) => {
                  Object.entries(product).forEach(([key, value]) => {
                    if (
                      key === "name" &&
                      value.toLowerCase().includes(e.target.value.toLowerCase())
                    )
                      filteredProducts.push(product);
                  });
                });
                setProducts(filteredProducts);
                setTempProducts(loadPage(filteredProducts));
              }
              setSearch(e.target.value);
            }}
          />
          <Button
            type="submit"
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              background: "black",
            }}
            onClick={(e) => onSearchHandler(e)}
          >
            Ara
            <i className="fa fa-search"></i>
          </Button>
        </form>
                    </div>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="table-scroll col-12">
                          <Table striped bordered hover>
                            <thead>
                              <tr className="text-bold text-center">
                              <th>Görsel</th>
                  <th>Ürün No</th>
                  <th>Ürün Adı</th>
                  <th>Ürün Kategorisi</th>
                  <th>Seçenekler</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tempProducts.length === 0 ? (
                                <p className="text-danger p-2">Ürün bulunamadı</p>
                              ) : (
                                tempProducts.slice(0, 10)
                              )}
                            </tbody>
                          </Table>
                          {products ? (
                            <Paginator
                              len={products.length}
                              data={products}
                              setTempProducts={setTempProducts}
                              loadPage={loadPage}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default Urunler;
