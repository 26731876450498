import { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, Button, Container, Card, CardTitle, CardBody, Row } from "reactstrap";
import { lengthError, typeError } from "../../form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import alertify from "alertifyjs";
import Slider from "../../components/Slider";
import { useLocation } from "react-router-dom";
import { FormApi } from "../../api/form_api";
import Layout from "../../Layout";

const dataSources = [
  {
    id: 1,
    dataURL: "https://picsum.photos/seed/1/600",
  },
  {
    id: 2,
    dataURL: "https://picsum.photos/seed/2/600",
  },
  {
    id: 3,
    dataURL: "https://picsum.photos/seed/3/600",
  },
  {
    id: 4,
    dataURL: "https://picsum.photos/seed/4/600",
  },
  {
    id: 5,
    dataURL: "https://picsum.photos/seed/5/600",
  },
  {
    id: 6,
    dataURL: "https://picsum.photos/seed/6/600",
  },
];
const dataSources2 = [
  {
    id: 1,
    dataURL: "https://picsum.photos/seed/1/600",
  },
  {
    id: 2,
    dataURL: "https://picsum.photos/seed/2/600",
  },
  {
    id: 3,
    dataURL: "https://picsum.photos/seed/3/600",
  },
  {
    id: 4,
    dataURL: "https://picsum.photos/seed/4/600",
  },
  {
    id: 5,
    dataURL: "https://picsum.photos/seed/5/600",
  },
  {
    id: 6,
    dataURL: "https://picsum.photos/seed/6/600",
  },
];
const endpoint = "productdenz";
function ProductAddFromPage() {
  const categoryOptionsTr = [
    "A Serisi Hava Valfleri",
    "B Serisi Kelebek Vanalar",
    "C Serisi Çek Valfler",
    "D Serisi Demontaj Bağlantıları",
    "E Serisi Kauçuk Kompansatörler",
    "F Serisi Ayak Valfleri",
    "G Serisi Sürgülü Vanalar",
    "H Serisi Yangın Hidrantları",
    "H Serisi Sulama Hidrantları",
    "J Serisi Kompansatörler",
    "L Serisi Küresel Vanalar",
    "M Serisi Aktüatörler",
    "O Serisi Debimetreler",
    "P Serisi Pistonlu Vana",
    "R Serisi Kontrol Vanaları | Küre Tip",
    "R Serisi Kontrol Vanaları | Y Tipi",
    "S Serisi Süzgeçler",
    "T Serisi Cebri Boru",
    "U Serisi Adaptörler & Kaplinler ve Bağlantı Elemanları",
    "V Serisi Flanşlar ve Aksesuarlar",
    "Y Serisi Diğer Ürünler",
  ];
  const categoryOptionsEn = [
    "A Series Air Valves",
    "B Series Butterfly Valves",
    "C Series Check Valves",
    "D Series Dismantling Joints",
    "E Series Rubber Expansion Joints",
    "F Series Foot Valves",
    "G Series Gate Valves",
    "H Series Fire Hydrants",
    "H Series Irrigation Hydrants",
    "J Series Expansion Joints",
    "L Series Ball Valves",
    "M Series Actuators",
    "O Series Flowmeters",
    "P Series Plunger Valve",
    "R Series Control Valves | Globe Type",
    "R Series Control Valves | Y Type",
    "S Series Strainers",
    "T Series Penstock",
    "U Series Adaptors & Couplings & Fittings",
    "V Series Flanges & Accessories",
    "Y Series Other Products",
  ];
  const categoryOptionsFr = [
    "Série A Ventouses",
    "Série B Vannes Papillon",
    "Série C Clapets Anti-retour",
    "Série D Joints de Démontage",
    "Série E Joints d’Expansion",
    "Série F Clapets de Pied",
    "Série G Vannes Opercule",
    "Série H Bornes d'Incendie",
    "Série H Bornes d’irrigation",
    "Série J Joints de Dilatation",
    "Série L Vannes à Boisseau Sphérique",
    "Série M Servomoteurs ",
    "Série O Débitmètres",
    "Série P Vanne À Pointeau",
    "Série R Vannes à Contrôle | Type de Globe",
    "Série R Vannes à Contrôle | Type de  Y",
    "Série S Filtres",
    "Série T",
    "Série U",
    "Série V Brides & Accessories",
    "Série Y Les Produits Autres",
  ];
  const categoryOptionsRu = [
    "Воздушные клапаны Серии A",
    "Дроссельные затворы  Серии B",
    "Обратные клапаны Серии C",
    "Демонтажные / Mонтажные вставки  Серии D",
    "Гибкие резиновые соединения  Серии E",
    "Донные клапаны  Серии F",
    "Задвижки Серии G",
    "Пожарные гидранты  Серии H",
    "Ирригационные гидранты серии H",
    "Компенсаторы  Серии J",
    "Шаровые краны  Серии L",
    "Приводы  Серии M",
    "Расходомеры   Серии О",
    "Плунжерные клапаны  Серии P",
    "Регулирующие клапаны  Серии R | Тип глобуса",
    "Регулирующие клапаны  Серии R | Y Тип",
    "Сетчатые фильтры  Серии S",
    "Шиберные затворы  Серии Т",
    "Адаптеры / Муфты / Фитинги  Серии  U",
    "Фланцы / Аксессуары  Серии V",
    "Другие продукты  Серии Y",
  ];
  const categoryOptionsAr = [
   "صمامات الهواء من السلسلة A",
   "صمامات الفراشة من السلسلة B",
   "صمامات الفحص من السلسلة C",
   "وصلات التفكيك من السلسلة D",
   "وصلات التمدد المطاطية من السلسلة E",
   "صمامات القدم من السلسلة F",
   "صمامات البوابة من السلسلة G",
   "صنابير الحريق من السلسلة H",
   "صنابير الري من السلسلة H",
   "وصلات التمدد من السلسلة J",
   "صمامات الكرة من السلسلة L",
   "مشغلات من السلسلة M",
   "مقاييس التدفق من السلسلة O",
   "صمام المكبس من السلسلة P",
   "صمامات التحكم من السلسلة R | النوع الكروي",
   "صمامات التحكم من السلسلة R | النوع Y",
   "مصافٍ من السلسلة S",
   "بوابات التحكم من السلسلة T",
   "محولات ووصلات وتركيبات من السلسلة U",
   "شُفَّه وإكسسوارات من السلسلة V",
   "منتجات أخرى من السلسلة Y"
   
  ];
  const formApi = new FormApi(endpoint);
  const location = useLocation();

  const [form, setForm] = useState({
    code: "",
    name: "",
    // name_tr: "",
    name_fr: "",
    name_ru: "",
    name_ar : "",
    img: "",
    categories_img: "",
    categories: "",
    // categories_tr: "",
    categories_fr: "",
    categories_ru: "",
    categories_ar : "",
    pdf_en: "",
    pdf_fr: "",
    pdf_ru: "",
    description_en: "",
    // description_tr: "",
    description_fr: "",
    description_ru: "",
    description_ar : "",
    // table_tr: "",
    table_en: "",
    table_fr: "",
    table_ru: "",
    table_ar : "",
    // app_areas_tr: "",
    app_areas_en: "",
    app_areas_fr: "",
    app_areas_ru: "",
    app_areas_ar : "",
    // features_tr: "",
    features_en: "",
    features_fr: "",
    features_ru: "",
    features_ar : "",
  });
  const [validation, setValidation] = useState({
    code: "",
    name: "",
    // name_tr: "",
    name_fr: "",
    name_ru: "",
    name_ar : "",
    img: "",
    categories_img: "",
    categories: "",
    // categories_tr: "",
    categories_fr: "",
    categories_ru: "",
    categories_ar : "",
    pdf_en: "",
    pdf_fr: "",
    pdf_ru: "",
    description_en: "",
    // description_tr: "",
    description_fr: "",
    description_ru: "",
    description_ar : "",
    table_en: "",
    // table_tr: "",
    table_fr: "",
    table_ru: "",
    table_ar : "",
    app_areas_en: "",
    // app_areas_tr: "",
    app_areas_fr: "",
    app_areas_ru: "",
    app_areas_ar : "",
    features_en: "",
    // features_tr: "",
    features_fr: "",
    features_ru: "",
    features_ar : "",
  });

  const validator = (key, value) => {
    if (value.length === 0 || value === "--" || value === "F") {
      setValidation((prevState) => ({ ...prevState, [key]: "F" }));
      return true;
    } else setValidation((prevState) => ({ ...prevState, [key]: "T" }));
  };
  useEffect(() => {
    const updatedForm = { ...form };
    for (let key in updatedForm) {
      if (updatedForm[key] === '') {
        updatedForm[key] = 'İçerik girilmemiştir.';
      }
    }
    setForm(updatedForm);
  }, []);

  const onChangeHandler = (key, value) => {
    setForm((prevState) => ({ ...prevState, [key]: value }));
    validator(key, value);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    let formData = new FormData();
    formData.append("img", form.img);
    formData.append("categories_img", form.categories_img);
    formData.append("name", form.name);
    // formData.append("name_tr", form.name_tr);
    formData.append("name_fr", form.name_fr);
    formData.append("name_ru", form.name_ru);
    formData.append("name_ar", form.name_ar);
    formData.append("code", form.code);
    formData.append("categories", form.categories);
    // formData.append("categories_tr", form.categories_tr);
    formData.append("categories_fr", form.categories_fr);
    formData.append("categories_ru", form.categories_ru);
    formData.append("categories_ar", form.categories_ar);
    formData.append("pdf_en", form.pdf_en);
    formData.append("pdf_fr", form.pdf_fr);
    formData.append("pdf_ru", form.pdf_ru);
    formData.append("description_en", form.description_en);
    // formData.append("description_tr", form.description_tr);
    formData.append("description_fr", form.description_fr);
    formData.append("description_ru", form.description_ru);
    formData.append("description_ar", form.description_ar);
    formData.append("table_en", form.table_en);
    // formData.append("table_tr", form.table_tr);
    formData.append("table_fr", form.table_fr);
    formData.append("table_ru", form.table_ru);
    formData.append("table_ar", form.table_ar);
    formData.append("app_areas_en", form.app_areas_en);
    // formData.append("app_areas_tr", form.app_areas_tr);
    formData.append("app_areas_fr", form.app_areas_fr);
    formData.append("app_areas_ru", form.app_areas_ru);
    formData.append("app_areas_ar", form.app_areas_ar);
    // formData.append("features_tr", form.features_tr);
    formData.append("features_en", form.features_en);
    formData.append("features_fr", form.features_fr);
    formData.append("features_ru", form.features_ru);
    formData.append("features_ar", form.features_ar);


    try {
      const rst = await formApi.addPage(formData);
      alertify.success(
        "Ürün eklendi..",
        ((window.location.href = "/urunler"), 1000),
        2.5
      );
    } catch (e) {
      alertify.error("Ürün eklenemedi..", 1000);
    }
  };

  const onUpdateHandler = async (e) => {
    e.preventDefault();
    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    if (isError) {
      alertify.error("Düzenlemeler yapılamadı..", 1000);

      return;
    } else {
      let formData = new FormData();
      formData.append("_id", form._id);
      formData.append("img", form.img);
      formData.append("categories_img", form.categories_img);
      formData.append("name", form.name);
      // formData.append("name_tr", form.name_tr);
      formData.append("name_fr", form.name_fr);
      formData.append("name_ru", form.name_ru);
      formData.append("name_ar", form.name_ar);
      formData.append("code", form.code);
      formData.append("categories", form.categories);
      // formData.append("categories_tr", form.categories_tr);
      formData.append("categories_fr", form.categories_fr);
      formData.append("categories_ru", form.categories_ru);
      formData.append("categories_ar", form.categories_ar);
      formData.append("pdf_en", form.pdf_en);
      formData.append("pdf_fr", form.pdf_fr);
      formData.append("pdf_ru", form.pdf_ru);
      formData.append("description_en", form.description_en);
      // formData.append("description_tr", form.description_tr);
      formData.append("description_fr", form.description_fr);
      formData.append("description_ru", form.description_ru);
      formData.append("description_ar", form.description_ar);
      formData.append("table_en", form.table_en);
      // formData.append("table_tr", form.table_tr);
      formData.append("table_fr", form.table_fr);
      formData.append("table_ru", form.table_ru);
      formData.append("table_ar", form.table_ar);

      formData.append("app_areas_en", form.app_areas_en);
      // formData.append("app_areas_tr", form.app_areas_tr);
      formData.append("app_areas_fr", form.app_areas_fr);
      formData.append("app_areas_ru", form.app_areas_ru);
      formData.append("app_areas_ar", form.app_areas_ar);
      formData.append("features_en", form.features_en);
      // formData.append("features_tr", form.features_tr);
      formData.append("features_fr", form.features_fr);
      formData.append("features_ru", form.features_ru);
      formData.append("features_ar", form.features_ar);

      try {
        const rst = await formApi.updatePage(formData);
        alertify.success(
          "Düzenlemeler yapıldı..",
          ((window.location.href = "/urunler"), 1000),
          2.5
        );
      } catch (e) {
        alertify.error("Düzenlemeler yapılamadı..", 1000);
      }
    }
  };

  useEffect(() => {
    if (location.state)
      Object.entries(location.state.product).forEach(([key, value]) => {
        onChangeHandler(key, value);
        setValidation((prevState) => ({ ...prevState, img: "T" }));
        setIsUpdate(true);
      });
    

  }, []);

  const imageUpload = (data) => {
    setForm((prevState) => ({ ...prevState, ["img"]: data }));
  };
  const imageUploadEn = (data) => {
    setForm((prevState) => ({ ...prevState, ["categories_img"]: data }));
  };
  const pdfUploadEn = (data) => {
    setForm((prevState) => ({ ...prevState, ["pdf_en"]: data }));
  };
  const pdfUploadFr = (data) => {
    setForm((prevState) => ({ ...prevState, ["pdf_fr"]: data }));
  };
  const pdfUploadRu = (data) => {
    setForm((prevState) => ({ ...prevState, ["pdf_ru"]: data }));
  };

  const [isUpdate, setIsUpdate] = useState(false);

  return (
    <Layout showFooter={true}>
       <main className="home">
       <section id="section-one">
        <Container>
          <Row>
            <Card>
              <CardBody>
             
                <br />
   
    <div className="container mt-3 pb-5">
    <h4>Ürün {isUpdate ? "Güncelleme" : "Ekleme"} Paneli</h4>
      <hr />
      <Form onSubmit={onSubmitHandler}>
        <Label>
          <b>Ürün Fotoğrafı(Fotografı seçtikten sonra lütfen bulut iconuna tıklayın.)</b>
        </Label>
        <Slider imageUpload={imageUpload} data={dataSources} />
        <br/>
        <Label>
          <b>Ürünün ait olduğu kategori fotoğrafı(lütfen ilgili kategorilere ilgili kategori fotograflarını ekleyiniz!)(Fotografı seçtikten sonra lütfen bulut iconuna tıklayın.)</b>
        </Label>
        <Slider imageUpload={imageUploadEn} data={dataSources2} />
        <br />
        <div className="row col-12">
          <div className="col-12">
            <FormGroup>
              <Label for="code">
                <b>Ürün Numarası</b>
              </Label>
              <Input
                id="code"
                name="code"
                placeholder="Lütfen ürün numarasını giriniz..."
                type="text"
                valid={validation.code === "T"}
                invalid={validation.code === "F"}
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.code}
                min={0}
              />
              {lengthError}
            </FormGroup>
            {/* <FormGroup>
              <Label for="name_tr">
                <b>Ürün Adı (tr)</b>
              </Label>
              <Input
                id="name_tr"
                name="name_tr"
                placeholder="Lütfen ürün adını (tr) giriniz..."
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.name_tr}
                valid={validation.name_tr === "T"}
                invalid={validation.name_tr === "F"}
              />
              {lengthError}
            </FormGroup> */}
            <FormGroup>
              <Label for="name">
                <b>Ürün Adı (en)</b>
              </Label>
              <Input
                id="name"
                name="name"
                placeholder="Lütfen ürün adını (en) giriniz..."
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.name}
                valid={validation.name === "T"}
                invalid={validation.name === "F"}
              />
              {lengthError}
            </FormGroup>
            <FormGroup>
              <Label for="name_fr">
                <b>Ürün Adı (fr)</b>
              </Label>
              <Input
                id="name_fr"
                name="name_fr"
                placeholder="Lütfen ürün adını (fr) giriniz..."
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.name_fr}
                valid={validation.name_fr === "T"}
                invalid={validation.name_fr === "F"}
              />
              {lengthError}
            </FormGroup>
            <FormGroup>
              <Label for="name_ru">
                <b>Ürün Adı (ru)</b>
              </Label>
              <Input
                id="name_ru"
                name="name_ru"
                placeholder="Lütfen ürün adını (ru) giriniz..."
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.name_ru}
                valid={validation.name_ru === "T"}
                invalid={validation.name_ru === "F"}
              />
              {lengthError}
            </FormGroup>

            <FormGroup>
              <Label for="name_ru">
                <b>Ürün Adı (ar)</b>
              </Label>
              <Input
                id="name_ar"
                name="name_ar"
                placeholder="Lütfen ürün adını (ar) giriniz..."
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.name_ar}
                valid={validation.name_ar === "T"}
                invalid={validation.name_ar === "F"}
              />
              {lengthError}
            </FormGroup>
            {/* <FormGroup>
              <Label for="categories_tr">
                <b>Ürün Kategori Adı (tr)</b>
              </Label>
              <br />
              <select
                id="categories_tr"
                name="categories_tr"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.categories_tr}
              >
                <option value="">Kategori Seçin (tr)</option>
                {categoryOptionsTr.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
              </select>
              {lengthError}
            </FormGroup> */}
            <FormGroup>
              <Label for="categories">
                <b>Ürün Kategori Adı (en)</b>
              </Label>
              <br />
              <select
                id="categories"
                name="categories"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.categories}
              >
                <option value="">Kategori Seçin (en)</option>
                {categoryOptionsEn.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
              </select>
              {lengthError}
            </FormGroup>

            <FormGroup>
              <Label for="categories_fr">
                <b>Ürün Kategori Adı (fr)</b>
              </Label>
              <br />
              <select
                id="categories_fr"
                name="categories_fr"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.categories_fr}
              >
                <option value="">Kategori Seçin (fr)</option>
                {categoryOptionsFr.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
              </select>
              {lengthError}
            </FormGroup>
            <FormGroup>
              <Label for="categories_ru">
                <b>Ürün Kategori Adı (ru)</b>
              </Label>
              <br />
              <select
                id="categories_ru"
                name="categories_ru"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.categories_ru}
              >
                <option value="">Kategori Seçin (ru)</option>
                {categoryOptionsRu.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
              </select>
              {lengthError}
            </FormGroup>
            <FormGroup>
              <Label for="categories_ar">
                <b>Ürün Kategori Adı (ar)</b>
              </Label>
              <br />
              <select
                id="categories_ar"
                name="categories_ar"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.categories_ar}
              >
                <option value="">Kategori Seçin (ar)</option>
                {categoryOptionsAr.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
              </select>
              {lengthError}
            </FormGroup>
          </div>
        </div>
        <br/>
        {/* <Label for="description_tr">
          <b>Ürün Açıklaması (tr)</b>
        </Label>
        <CKEditor
          name="description_tr"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.description_tr!== "") editor.setData(form.description_tr);
          }}
          onChange={(event, editor) =>
            onChangeHandler("description_tr", editor.getData())
          }
        /> */}
        <Label for="description_en">
          <b>Ürün Açıklaması (en)</b>
        </Label>
        <CKEditor
          name="description_en"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.description_en !== "") editor.setData(form.description_en);
          }}
          onChange={(event, editor) =>
            onChangeHandler("description_en", editor.getData())
          }
        />
         <br />
        <Label for="description_fr">
          <b>Ürün Açıklaması (fr)</b>
        </Label>
        <CKEditor
          name="description_fr"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.description_fr !== "") editor.setData(form.description_fr);
          }}
          onChange={(event, editor) =>
            onChangeHandler("description_fr", editor.getData())
          }
        />
        <br />
        <Label for="description_ru">
          <b>Ürün Açıklaması (ru)</b>
        </Label>
        <CKEditor
          name="description_ru"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.description_ru !== "") editor.setData(form.description_ru);
          }}
          onChange={(event, editor) =>
            onChangeHandler("description_ru", editor.getData())
          }
        />
        <br />
        <Label for="description_ar">
          <b>Ürün Açıklaması (ar)</b>
        </Label>
        <CKEditor
          name="description_ar"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.description_ar !== "") editor.setData(form.description_ar);
          }}
          onChange={(event, editor) =>
            onChangeHandler("description_ar", editor.getData())
          }
        />
        <br />
        {/* <Label for="table_tr">
          <b>Ürün Referans Tablosu (tr)</b>
        </Label>
        <CKEditor
          name="table_tr"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.table_tr !== "") editor.setData(form.table_tr);
          }}
          onChange={(event, editor) =>
            onChangeHandler("table_tr", editor.getData())
          }
        />
        <br /> */}
        <Label for="table_en">
          <b>Ürün Referans Tablosu (en)</b>
        </Label>
        <CKEditor
          name="table_en"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.table_en !== "") editor.setData(form.table_en);
          }}
          onChange={(event, editor) =>
            onChangeHandler("table_en", editor.getData())
          }
        />
        <br />
        <Label for="table_fr">
          <b>Ürün Referans Tablosu (fr)</b>
        </Label>
        <CKEditor
          name="table_fr"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.table_fr !== "") editor.setData(form.table_fr);
          }}
          onChange={(event, editor) =>
            onChangeHandler("table_fr", editor.getData())
          }
        />
    
        <br />
        <Label for="table_ru">
          <b>Ürün Referans Tablosu (ru)</b>
        </Label>
        <CKEditor
          name="table_ru"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.table_ru !== "") editor.setData(form.table_ru);
          }}
          onChange={(event, editor) =>
            onChangeHandler("table_ru", editor.getData())
          }
        />
        <br />
        <Label for="table_ar">
          <b>Ürün Referans Tablosu (ar)</b>
        </Label>
        <CKEditor
          name="table_ar"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.table_ar !== "") editor.setData(form.table_ar);
          }}
          onChange={(event, editor) =>
            onChangeHandler("table_ar", editor.getData())
          }
        />
        <br />
        {/* <Label for="app_areas_tr">
          <b>Ürün uygulama alanları (tr)</b>
        </Label>
        <CKEditor
          name="app_areas_tr"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.app_areas_tr !== "") editor.setData(form.app_areas_tr);
          }}
          onChange={(event, editor) =>
            onChangeHandler("app_areas_tr", editor.getData())
          }
        />
        <br /> */}
        <Label for="app_areas_en">
          <b>Ürün uygulama alanları (en)</b>
        </Label>
        <CKEditor
          name="app_areas_en"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.app_areas_en !== "") editor.setData(form.app_areas_en);
          }}
          onChange={(event, editor) =>
            onChangeHandler("app_areas_en", editor.getData())
          }
        />
        <br />
        <Label for="app_areas_fr">
          <b>Ürün uygulama alanları (fr)</b>
        </Label>
        <CKEditor
          name="app_areas_fr"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.app_areas_fr !== "") editor.setData(form.app_areas_fr);
          }}
          onChange={(event, editor) =>
            onChangeHandler("app_areas_fr", editor.getData())
          }
        />
        <br />
        <Label for="app_areas_ru">
          <b>Ürün uygulama alanları (ru)</b>
        </Label>
        <CKEditor
          name="app_areas_ru"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.app_areas_ru !== "") editor.setData(form.app_areas_ru);
          }}
          onChange={(event, editor) =>
            onChangeHandler("app_areas_ru", editor.getData())
          }
        />
        <br />
        <Label for="app_areas_ar">
          <b>Ürün uygulama alanları (ar)</b>
        </Label>
        <CKEditor
          name="app_areas_ar"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.app_areas_ar !== "") editor.setData(form.app_areas_ar);
          }}
          onChange={(event, editor) =>
            onChangeHandler("app_areas_ar", editor.getData())
          }
        />
        <br />
        {/* <Label for="features_tr">
          <b>Ürün Referansları (tr)</b>
        </Label>
        <CKEditor
          name="features_tr"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.features_tr !== "") editor.setData(form.features_tr);
          }}
          onChange={(event, editor) =>
            onChangeHandler("features_tr", editor.getData())
          }
        />
        <br /> */}
        <Label for="features_en">
          <b>Ürün Referansları (en)</b>
        </Label>
        <CKEditor
          name="features_en"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.features_en !== "") editor.setData(form.features_en);
          }}
          onChange={(event, editor) =>
            onChangeHandler("features_en", editor.getData())
          }
        />
        <br />
         <br />
        <Label for="features_fr">
          <b>Ürün Referansları (fr)</b>
        </Label>
        <CKEditor
          name="features_fr"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.features_fr !== "") editor.setData(form.features_fr);
          }}
          onChange={(event, editor) =>
            onChangeHandler("features_fr", editor.getData())
          }
        />
        <br />
        <Label for="features_ru">
          <b>Ürün Referansları (ru)</b>
        </Label>
        <CKEditor
          name="features_ru"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.features_ru !== "") editor.setData(form.features_ru);
          }}
          onChange={(event, editor) =>
            onChangeHandler("features_ru", editor.getData())
          }
        />
        <br />
        <Label for="features_ar">
          <b>Ürün Referansları (ar)</b>
        </Label>
        <CKEditor
          name="features_ar"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.features_ar !== "") editor.setData(form.features_ar);
          }}
          onChange={(event, editor) =>
            onChangeHandler("features_ar", editor.getData())
          }
        />
        <br />
        <Label>
          <b>PDF (en)</b>
        </Label>
        <input
          type="file"
          accept="pdf/*"
          onChange={(e) => pdfUploadEn(e.target.files[0])}
        />
        <br />
        <Label>
          <b>PDF (fr)</b>
        </Label>
        <input
          type="file"
          accept="pdf/*"
          onChange={(e) => pdfUploadFr(e.target.files[0])}
        />
        <br />
        <Label>
          <b>PDF (ru)</b>
        </Label>
        <input
          type="file"
          accept="pdf/*"
          onChange={(e) => pdfUploadRu(e.target.files[0])}
        />
        <br />
        <br />
          <Button color="dark" type="submit" onClick={(e) => isUpdate ? onUpdateHandler(e) : onSubmitHandler(e)}>
                        {isUpdate ? "Güncelle" : "Ekle"}
                      </Button>
      </Form>
    </div>
    </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
    </main>
    </Layout>
  );
}

export default ProductAddFromPage;