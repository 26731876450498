import React, { useState } from "react";

function CustomAlert({ message, type }) {
  const [visible, setVisible] = useState(true);

  const hideAlert = () => {
    setVisible(false);
  };

  return (
    visible && (
      <div className={`alert alert-${type}`} role="alert">
      
          <span aria-hidden="true">&times;</span>
        
        {message}
      </div>
    )
  );
}

export default CustomAlert;
