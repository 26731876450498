import { NavLink } from "react-router-dom";
import { NavItem } from "reactstrap";
import { useState, useEffect, useRef } from "react";
function NavBarItem(props) {
  const { title, to, icon, tabIndex } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isLogOut, setIsLogOut] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const onLogOut = (e) => {
    setIsLogOut(true);
    setTimeout(() => {
      window.location.href = "/login";
      localStorage.removeItem("asdf");
    }, 2000);
  };
  return (
    <NavLink
      tabIndex={tabIndex}
      to={`${to ? `/${to}` : "/"}`}
      className={({ isActive }) => (isActive ? "active" : "")}
    >
      <NavItem className="d-flex align-items-center justify-content-start gap-2 nav-item">
        <div className="nav-item-img-wrapper text-center">
          <img
            src={`/assets/icons/${icon}`}
            alt="nav-icon"
            className="nav-item-img"
          />
        </div>
        <div className="nav-item-title">{title}</div>
      </NavItem>
    </NavLink>
  );
}

export default NavBarItem;
