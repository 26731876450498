import { useEffect } from "react";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { toggleIsDark } from "../../redux/themeSlice";
function ThemeSwitcher({ className }) {
  const theme = useSelector((state) => state?.theme?.theme);

  const dispatch = useDispatch();

  useEffect(() => {
    applyTheme(theme);
  }, [theme]);

  const applyTheme = (selectedTheme) => {
    const bodyElement = document.getElementsByTagName("body")[0];
    bodyElement.setAttribute("data-bs-theme", selectedTheme);
  };

  return (
    <div
      className={`theme-switcher d-flex align-items-center rounded-pill py-1 justify-content-between position-relative ${
        className ? className : ""
      } ${theme === "dark" ? "" : "active"}`}
      onClick={() => {
        dispatch(toggleIsDark());
        applyTheme(theme);
      }}
    >
      <Icon
        width={20}
        height={20}
        icon="humbleicons:sun"
        color="white"
        className="sun"
      />
      <Icon
        width={16}
        height={16}
        icon="bi:moon-fill"
        color="black"
        className="moon"
      />
      <div className="rounded-circle position-absolute"></div>
    </div>
  );
}

export default ThemeSwitcher;
